import React, { useContext, useEffect } from "react"
import t from '../services/GetTranslationValue'
import mrg from '../../static/mrg/Loading.gif'
import ThemeContext from "../context/ThemeContext"

import "../components/reset.css"
import "../components/layout.scss"
const RedirectionPage = ({data, pageContext, location}) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      if (window)
        window.location = pageContext.link;
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const { translations }= useContext(ThemeContext);

  return (
    <div className="page-404">
      <p>
        {t.get(translations, 'redirect-text')}
      </p>
      <img src={mrg} />
    </div>
  )
}

export default RedirectionPage

